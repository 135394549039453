@media only screen and (max-width: 1556px) {
    .elevate-your {
        padding: 50px 25px;
    }
  }
  @media only screen and (max-width: 1300px) {
    .face-moisturizer {
        padding-right: 16px;
        .user-img {
            img {
                height: 500px;
            }
        }
        .love-it {
            h5 {
                font-size: 43px;
                line-height: 33.41px;
            }
            h6 {
                font-size: 29px;
                line-height: 30.13px;
            }
            p {
                font-size: 17px;
                line-height: 30px;
            }
        }
    }    
    .elevate-your {
        .elevate-img {
            height: 500px;
        }
        .elevate-text {
            h5 {
                font-size: 40px;
            }
            h6 {
                font-size: 40px;
                line-height: 65px;
            }
        }
    }
  }
  @media only screen and (max-width: 1000px) {
    .face-moisturizer{
        .col-6{
            flex: 0 0 auto;
            width: 100%;
            margin-top: 20px;
        }
    }
    .elevate-your {
        .elevate-img {
            height: 350px;
        }
        .elevate-text {
            h5 {
                font-size: 32px;
                line-height: 40px;
            }
            h6 {
                font-size: 30px;
                line-height: 52px;        
            }
        }
    }
  }
  @media only screen and (max-width: 774px) {
    .our-product {
        padding-left: 25px;
        padding-right: 25px;
    }
    .latest-update {
        h5 {
            font-size: 40px;
            line-height: 62.01px;
        }
        p {
            font-size: 26px;
        }
    }
    .elevate-your {
        display: flex;
        flex-direction: column-reverse;

        .col-6 {
            flex: 0 0 auto;
            width: 100%;
        }
    }
  }
  @media only screen and (max-width: 540px) {
    .our-product {
        padding-left: 0px;
        padding-right: 0px;
        .main-box{
            padding: 4px 8px !important;
            .product-box {
                h6 {
                    font-size: 17px;
                    line-height: 22px;
                    padding: 1px;
                }
            }
        }
        
    } 
    .title {
        color: #FE6AC4;
        font-family: sans-serif;
        font-size: 45px;
        font-weight: 600;
        line-height: 30px;
        margin-top: 32px;
    }
    .main-logo{
      .icon-map{
        font-size: 25px;      
      }
    }
    .latest-update {
        h5 {
            font-size: 30px;
            line-height: 46px;
        }
        p {
            font-size: 22px;
            margin-bottom: 1px;
        }
        .email-subscribe {
            input {
                width: 226px;
            }
        }
    }
    .face-moisturizer {
        padding-right: 0px;
        .user-img {
            img {
                height: 394px;
            }
        }
        .love-it {
            h5 {
                font-size: 30px;
                line-height: 24px;
            }
            h6 {
                font-size: 24px;
                line-height: 14px;
            }
        
        }
    }
    .our-story {
        h6 {
            font-size: 50px;
        }
        p {
            font-size: 18px;
            line-height: 32px;
            width: 90%;
            margin: 0;
        }
    }
    
  }
  @media only screen and (max-width: 440px) {
    .face-moisturizer {
        .user-img {
            img {
                height: 319px;
            }
        }
    }
    .our-story {
        h6 {
            font-size: 40px;
            line-height: 54px;
        }
        p {
            font-size: 18px;
            line-height: 32px;
            width: 90%;
        }
    }
    .elevate-your {
        .elevate-img {
            height: 308px;
        }
        .elevate-text {
            h5 {
                margin-top: 20px;
                font-size: 28px;
                line-height: 40px;
            }
            h6 {
                font-size: 20px;
                line-height: 40px;
            }
        }
    }
    .title {
        font-size: 40px;
        line-height: 68px;
        margin-top: 18px;
    }
  }
  @media only screen and (max-width: 400px) {
    .our-product {
        .product-box {
            .btn-buy {
                padding: 5px 20px;
                font-size: 17px;
            }
        }
    }
    .our-product {
        .main-box {
            .product-box {
                h6 {
                    font-size: 15px;
                    line-height: 22px;
                    padding: 1px;
                }
            }
        }
    }
  }
  @media only screen and (max-width: 370px) {
    .latest-update {
        .email-subscribe {
            input {
                width: 198px;
            }
            button {
                padding: 10px 15px;
            }
        }
    }
  }