.organic{
    align-items: center;
    background-image: url(../images/12.png);
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .organic-step{
        margin-top: 80px;
        width: 20%;
        .step{
            margin-top: 30px;
            margin-bottom: 0px;
        }
      img{
        margin: 0 auto 30px auto;
        max-width: 200px;
        border-radius: 18px;
        transform: rotate(407deg);
      }
      h5{
        font-size: 24px;
        line-height: 1.417;
        font-weight: 500;
      }
      p{
        color: #54595F;
      }
    }    
}
