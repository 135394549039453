.our-product{
   padding-left: 100px;
   padding-right: 100px;
   
    .product-box{
       background-color: #FFFFFF;
       box-shadow: 0px 5px 6px 0px #0000000D;
       padding-bottom: 18px;
       border-radius: 20px;

       img{
         width: 100%;
       }

       h6{
           font-size: 23px;
           margin-top: 20px;
           margin-bottom: 20px;     
       }
       .btn-buy{
          padding: 10px 26px;
          font-size: 18px;    
       }
    }
}
   .elevate-your{
      padding: 50px 100px;
      .elevate-text{
         display: flex;
         flex-direction: column;
         justify-content: center;

         h5{
            color:  #FE6AC4;
            font-family: sans-serif;
            font-size: 58px;
            font-weight: 700;
            line-height: 100px;
            text-align: left;
         }
         h6{
            color:  #343434;
            font-family: sans-serif;
            font-size: 58px;
            font-weight: 700;
            line-height: 100px;    
            text-align: left;        
         }
      }
      .elevate-img{
         height: 660px;
      }
   }
   .our-story{
      background-image: url("../images/08.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding: 30px;
      h6{
         font-family: sans-serif;
         font-size: 60px;
         font-weight: 500;
         line-height: 74.41px;
         color: #FFFFFF;
         text-align: center;
      }
      p{
         font-family: sans-serif;
         font-size: 30px;
         font-weight: 500;
         line-height: 56px;
         text-align: center;
         color: #E0E0E0;
         width: 70%;
      }
   }
   .face-moisturizer{
      background-color: #FA8ED0;
      padding-right: 100px;
      padding-top: 20px;
      padding-bottom: 20px;
      .user-img{
         img{
            height: 628px;
         }
      }
      
      .love-it{
         background-color: #FFFFFF;
         padding: 22px;
         display: flex;
         flex-direction: column;
         gap: 22px;
         border-radius: 12px;

         h5{
            font-family: sans-serif;
            font-size: 60px;
            font-weight: 600;
            line-height: 74.41px;
            text-align: left;
            color: #343434;
         }

         h6{
            color: #EA46AA;
            font-family: sans-serif;
            font-size: 38px;
            font-weight: 600;
            line-height: 47.13px;
            text-align: left;
         }

         p{
            color: #838383;
            font-family: sans-serif;
            font-size: 22px;
            font-weight: 400;
            line-height: 36px;
            text-align: left;
         }

      }
   }
   .latest-update{
      background-color: #4CE5E5;
      padding: 32px 10px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      h5{
         color: #FFFFFF;
         font-family: sans-serif;
         font-size: 50px;
         font-weight: 600;
         line-height: 62.01px;
         text-align: left;

      }
      p{
         color: #222222;
         font-family: sans-serif;
         font-size: 28px;
         font-weight: 400;
         line-height: 36.4px;
         text-align: center;
      }
      .email-subscribe{
         margin-top: 20px;

         input{
            background-color: #F8F8F8;
            color: #3A3A3A;
            padding: 10px 19px;
            border: 0;
            width: 394px;

         }
         button{
            background-color: #222222;
            color: #DEDEDE;
            padding: 10px 22px;
            border: 0px;
         }
      }
   }
   
   .our-product{
      .arrow-icons{
         .slick-arrow{
            color: #EA46AA !important;
            height: 40px;
            width: 40px;
         }        
      }
   }