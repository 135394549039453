.main-nav {
  position: relative;
  background: linear-gradient(359deg, rgba(76, 229, 229, 0.5490196078) 13.37%, rgba(255, 104, 175, 0.5411764706) 87.22%);
  padding: 20px;
}
.main-nav .logo {
  height: 178px;
}
.main-nav .main-search {
  position: absolute;
  top: 32%;
  right: 3%;
}
.main-nav .main-search .search-box {
  position: relative;
}
.main-nav .main-search .search-box .search-icon {
  color: #828282;
  position: absolute;
  top: 25%;
  left: 5%;
  font-size: 22px;
}
.main-nav .main-search .search-box .input-search {
  color: black;
  border: 1px solid #B7B7B7;
  border-radius: 122px;
  padding: 10px 44px;
}

.our-product {
  padding-left: 100px;
  padding-right: 100px;
}
.our-product .product-box {
  background-color: #FFFFFF;
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.0509803922);
  padding-bottom: 18px;
  border-radius: 20px;
}
.our-product .product-box img {
  width: 100%;
}
.our-product .product-box h6 {
  font-size: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.our-product .product-box .btn-buy {
  padding: 10px 26px;
  font-size: 18px;
}

.elevate-your {
  padding: 50px 100px;
}
.elevate-your .elevate-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.elevate-your .elevate-text h5 {
  color: #FE6AC4;
  font-family: sans-serif;
  font-size: 58px;
  font-weight: 700;
  line-height: 100px;
  text-align: left;
}
.elevate-your .elevate-text h6 {
  color: #343434;
  font-family: sans-serif;
  font-size: 58px;
  font-weight: 700;
  line-height: 100px;
  text-align: left;
}
.elevate-your .elevate-img {
  height: 660px;
}

.our-story {
  background-image: url("../images/08.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 30px;
}
.our-story h6 {
  font-family: sans-serif;
  font-size: 60px;
  font-weight: 500;
  line-height: 74.41px;
  color: #FFFFFF;
  text-align: center;
}
.our-story p {
  font-family: sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 56px;
  text-align: center;
  color: #E0E0E0;
  width: 70%;
}

.face-moisturizer {
  background-color: #FA8ED0;
  padding-right: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.face-moisturizer .user-img img {
  height: 628px;
}
.face-moisturizer .love-it {
  background-color: #FFFFFF;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 22px;
  border-radius: 12px;
}
.face-moisturizer .love-it h5 {
  font-family: sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 74.41px;
  text-align: left;
  color: #343434;
}
.face-moisturizer .love-it h6 {
  color: #EA46AA;
  font-family: sans-serif;
  font-size: 38px;
  font-weight: 600;
  line-height: 47.13px;
  text-align: left;
}
.face-moisturizer .love-it p {
  color: #838383;
  font-family: sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
}

.latest-update {
  background-color: #4CE5E5;
  padding: 32px 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.latest-update h5 {
  color: #FFFFFF;
  font-family: sans-serif;
  font-size: 50px;
  font-weight: 600;
  line-height: 62.01px;
  text-align: left;
}
.latest-update p {
  color: #222222;
  font-family: sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36.4px;
  text-align: center;
}
.latest-update .email-subscribe {
  margin-top: 20px;
}
.latest-update .email-subscribe input {
  background-color: #F8F8F8;
  color: #3A3A3A;
  padding: 10px 19px;
  border: 0;
  width: 394px;
}
.latest-update .email-subscribe button {
  background-color: #222222;
  color: #DEDEDE;
  padding: 10px 22px;
  border: 0px;
}

.our-product .arrow-icons .slick-arrow {
  color: #EA46AA !important;
  height: 40px;
  width: 40px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  background-color: #FFF6FB !important;
}

.title {
  color: #FE6AC4;
  font-family: sans-serif;
  font-size: 60px;
  font-weight: 600;
  line-height: 120px;
}

.btn-primary {
  background-color: #3A3A3A !important;
  color: #FFFFFF !important;
  border: 1px solid #3A3A3A !important;
}

.section-title {
  align-items: center;
  background-image: url("../images/11.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
}
.section-title h5 {
  color: #fff;
  font-family: sans-serif;
  font-size: 90px;
  font-weight: 700;
  line-height: 108px;
}
.section-title h6 {
  color: #fff;
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
}

.organic {
  align-items: center;
  background-image: url(../images/12.png);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.organic .organic-step {
  margin-top: 80px;
  width: 20%;
}
.organic .organic-step .step {
  margin-top: 30px;
  margin-bottom: 0px;
}
.organic .organic-step img {
  margin: 0 auto 30px auto;
  max-width: 200px;
  border-radius: 18px;
  transform: rotate(407deg);
}
.organic .organic-step h5 {
  font-size: 24px;
  line-height: 1.417;
  font-weight: 500;
}
.organic .organic-step p {
  color: #54595F;
}

@media only screen and (max-width: 1556px) {
  .elevate-your {
    padding: 50px 25px;
  }
}
@media only screen and (max-width: 1300px) {
  .face-moisturizer {
    padding-right: 16px;
  }
  .face-moisturizer .user-img img {
    height: 500px;
  }
  .face-moisturizer .love-it h5 {
    font-size: 43px;
    line-height: 33.41px;
  }
  .face-moisturizer .love-it h6 {
    font-size: 29px;
    line-height: 30.13px;
  }
  .face-moisturizer .love-it p {
    font-size: 17px;
    line-height: 30px;
  }
  .elevate-your .elevate-img {
    height: 500px;
  }
  .elevate-your .elevate-text h5 {
    font-size: 40px;
  }
  .elevate-your .elevate-text h6 {
    font-size: 40px;
    line-height: 65px;
  }
}
@media only screen and (max-width: 1000px) {
  .face-moisturizer .col-6 {
    flex: 0 0 auto;
    width: 100%;
    margin-top: 20px;
  }
  .elevate-your .elevate-img {
    height: 350px;
  }
  .elevate-your .elevate-text h5 {
    font-size: 32px;
    line-height: 40px;
  }
  .elevate-your .elevate-text h6 {
    font-size: 30px;
    line-height: 52px;
  }
}
@media only screen and (max-width: 774px) {
  .our-product {
    padding-left: 25px;
    padding-right: 25px;
  }
  .latest-update h5 {
    font-size: 40px;
    line-height: 62.01px;
  }
  .latest-update p {
    font-size: 26px;
  }
  .elevate-your {
    display: flex;
    flex-direction: column-reverse;
  }
  .elevate-your .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
}
@media only screen and (max-width: 540px) {
  .our-product {
    padding-left: 0px;
    padding-right: 0px;
  }
  .our-product .main-box {
    padding: 4px 8px !important;
  }
  .our-product .main-box .product-box h6 {
    font-size: 17px;
    line-height: 22px;
    padding: 1px;
  }
  .title {
    color: #FE6AC4;
    font-family: sans-serif;
    font-size: 45px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 32px;
  }
  .main-logo .icon-map {
    font-size: 25px;
  }
  .latest-update h5 {
    font-size: 30px;
    line-height: 46px;
  }
  .latest-update p {
    font-size: 22px;
    margin-bottom: 1px;
  }
  .latest-update .email-subscribe input {
    width: 226px;
  }
  .face-moisturizer {
    padding-right: 0px;
  }
  .face-moisturizer .user-img img {
    height: 394px;
  }
  .face-moisturizer .love-it h5 {
    font-size: 30px;
    line-height: 24px;
  }
  .face-moisturizer .love-it h6 {
    font-size: 24px;
    line-height: 14px;
  }
  .our-story h6 {
    font-size: 50px;
  }
  .our-story p {
    font-size: 18px;
    line-height: 32px;
    width: 90%;
    margin: 0;
  }
}
@media only screen and (max-width: 440px) {
  .face-moisturizer .user-img img {
    height: 319px;
  }
  .our-story h6 {
    font-size: 40px;
    line-height: 54px;
  }
  .our-story p {
    font-size: 18px;
    line-height: 32px;
    width: 90%;
  }
  .elevate-your .elevate-img {
    height: 308px;
  }
  .elevate-your .elevate-text h5 {
    margin-top: 20px;
    font-size: 28px;
    line-height: 40px;
  }
  .elevate-your .elevate-text h6 {
    font-size: 20px;
    line-height: 40px;
  }
  .title {
    font-size: 40px;
    line-height: 68px;
    margin-top: 18px;
  }
}
@media only screen and (max-width: 400px) {
  .our-product .product-box .btn-buy {
    padding: 5px 20px;
    font-size: 17px;
  }
  .our-product .main-box .product-box h6 {
    font-size: 15px;
    line-height: 22px;
    padding: 1px;
  }
}
@media only screen and (max-width: 370px) {
  .latest-update .email-subscribe input {
    width: 198px;
  }
  .latest-update .email-subscribe button {
    padding: 10px 15px;
  }
}
.main-logo {
  padding: 22px;
  background-color: #222222;
}
.main-logo .menu-link h6 {
  font-family: sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 30.26px;
  color: #FFFFFF;
  text-align: start;
}
.main-logo .menu-link .social-icons {
  font-size: 20px;
}
.main-logo .menu-link ul {
  padding-left: 4px;
}
.main-logo .menu-link ul li {
  list-style-type: none;
  text-align: start;
}
.main-logo .menu-link ul li a {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #FFFFFF;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 31.2px;
}
.main-logo .menu-link .follow-icon {
  background-color: #FFFFFF;
  padding: 5px;
  color: #222222;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-logo .menu-link .follow-icon .icon {
  font-size: 30px;
}/*# sourceMappingURL=style.css.map */