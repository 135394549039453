.main-logo{
    padding: 22px;
    background-color: #222222;
    .menu-link{
       
        h6{
            font-family: sans-serif;
            font-size: 25px;
            font-weight: 600;
            line-height: 30.26px;
            color: #FFFFFF;
            text-align: start;
        }
        .social-icons{
            font-size: 20px;
        }
        
        ul{
            padding-left: 4px;
            li{
                list-style-type: none;
                text-align: start;
                a{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    text-decoration: none;
                    color: #FFFFFF;
                    font-family: sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 31.2px;
                }
            }
        }
        .follow-icon{
            background-color: #FFFFFF;
            padding: 5px;
            color: #222222;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            .icon{
                font-size: 30px;
            }
        }
    }
}