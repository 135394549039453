.main-nav{
    position: relative;
    background: linear-gradient(359deg, #4ce5e58c 13.37%, #ff68af8a 87.22%);
    padding: 20px;
    .logo{
        height: 178px;
    }
    .main-search{
        position: absolute;
        top: 32%;  
        right: 3%;
        .search-box{
            position: relative;
            .search-icon{
                color: #828282;
                position: absolute;
                top: 25%;
                left: 5%;
                font-size: 22px;
            }
            .input-search{
                color: black;
                border: 1px solid #B7B7B7;
                border-radius: 122px;
                padding: 10px 44px;
            }
        }
    }
   
}