*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html{
    overflow-x: hidden;
}
body{
    background-color: #FFF6FB !important;
}

.title{
    color: #FE6AC4;
    font-family: sans-serif;
    font-size: 60px;
    font-weight: 600;
    line-height: 120px;
}
.btn-primary{
    background-color:  #3A3A3A !important;
    color: #FFFFFF !important;
    border: 1px solid #3A3A3A !important;
}
.section-title{
    align-items: center;
    background-image: url("../images/11.png");
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 300px;
    justify-content: center;
    h5 {
        color: #fff;
        font-family: sans-serif;
        font-size: 90px;
        font-weight: 700;
        line-height: 108px;
    }
    h6 {
        color: #fff;
        font-family: sans-serif;
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
    }
}